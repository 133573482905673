<template>
  <div>
    <div class="container mt-5">
      <div class="row">
        <div class="col">
          <div class="card" style="margin-bottom: 49px;">
            <div class="card-header p-3" style="border-bottom: 0px; background-color: #EAEAEA">
              <span class="card-title h6 mt-2">My info and settings</span>
            </div>

            <div class="card-body p-3" style="padding: 0;">
              <div class="d-flex justify-content-center">
                <div>
                  <img
                    class="rounded-circle big-avatar"
                    :src="user.steamData ? user.steamData.avatarfull : require('@/assets/steam_default_profile_pic.png')"
                  />
                  <div
                    class="mt-2 text-center"
                  >{{ user.username }}</div>
                </div>
              </div>

              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between form-inline mt-2 mb-2">
                  <div class="flex-grow-1 form-text">Custom Store URL</div>
                  <div v-tooltip="'Copy to Clipboard'" @click="copyToClipboard(storeUrl)" style="margin-right: 42px; cursor: pointer;">
                    <icon-contentcopy  />
                  </div>

                  <div class="item-search form-group">
                    <input class="form-control g-textbox my-textbox" type="text" v-model="storeUrl" disabled style="background-color: #e5e5e5 !important"/>
                  </div>
                  <div class="pl-2">
                    <button class="btn btn-lg btn-secondary">SAVE</button>
                  </div>
                </div>

                <div class="hr"></div>

                <div class="d-flex justify-content-between form-inline mt-2 mb-2">
                  <div class="flex-grow-1 form-text">Steam Trade Link</div>

                  <div v-if="user.steamData" v-tooltip="'Click me to find your tradeoffers URL!'" @click="openTradeoffersInNewTab" style="margin-right: 42px; cursor: pointer;">
                    <icon-openinnew  />
                  </div>
                  

                  <div class="item-search form-group">
                    <input
                      class="form-control g-textbox my-textbox"
                      
                      type="text"
                      v-model="tradeLink"
                      v-on:keyup.enter="updateTradeLink()"
                    />
                  </div>
                  <div class="pl-2">
                    <button class="btn btn-lg btn-secondary" v-on:click="updateTradeLink()">SAVE</button>
                  </div>
                </div>
              </div>
              
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between form-inline mt-2 mb-2">
                  <div>
                    <div class="flex-grow-1 form-text">Steam Account</div>

                  </div>
                
                  <div class="d-flex" v-if="user.steamData"  @click="openSteamInNewTab" style=" cursor: pointer;" >
                    <div>{{ user.steamData.personaname }}</div>

                    <div class="pl-2" v-if="user.steamData" v-tooltip="'Open steam profile'" style="margin-right: 42px;">
                      <icon-openinnew  />
                    </div>
                  </div>

                  <div class="" v-else @click="connectSteam()" style="cursor: pointer; max-width: 136px;"> 
                    <img src="@/assets/sits_01.png" alt="" srcset="" style="max-width: 136px;">
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="card-footer p-4"> </div> -->
          </div>

          <div class="card mt-3">
            <div class="card-header p-3" style="border-bottom: 0px; background-color: #EAEAEA">
              <span class="card-title h6 mt-2">Security</span>
            </div>

            <div class="card-body p-3" style="padding: 0;">
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between form-inline mt-2 mb-2">
                  <span class="flex-grow-1 form-text">Email:</span>
                  <div class="pl-2">
                    <button class="btn btn-lg btn-secondary" @click="changeEmail()">{{ user.emailConfirmedAt ? 'Update' : 'Verify' }}</button>
                  </div>
                </div>

                <div class="d-flex justify-content-between form-inline mt-2 mb-2">
                  <span class="flex-grow-1 form-text">Password: </span>
                  <div class="pl-2" v-if="user && user.lastPwChange">
                    <button class="btn btn-lg btn-secondary" @click="changePassword()">Update</button>
                  </div>
                  <div class="pl-2" v-else>
                    <button class="btn btn-lg btn-secondary" @click="setupPassword()">Set Up</button>
                  </div>
                </div>

                <div v-for="item in invalidPassReqs" class="mt-2 mb-2">
                  <span>{{ item }}</span>
                </div>

                <div class="hr"></div>

                <div class="d-flex justify-content-between form-inline mt-2 mb-2">
                  <div class="flex-grow-1 form-text">Two Factor Authentication:</div>
                  <div class="pl-2">
                    <button v-if="!user.twoFactorEnabled" class="btn btn-lg btn-secondary" v-on:click="setupTwoFactor()">Set Up</button>
                    <button v-else class="btn btn-lg btn-danger" @click="removeTwoFactor">Remove</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="card-footer p-4"> </div> -->
          </div>
        </div>

        <!-- Second col -->
        <div class="col">
          <div class="card">
            <div class="card-header p-3" style="border-bottom: 0px; background-color: #EAEAEA">
              <span class="card-title h6 mt-2">Buyer history</span>
            </div>

            <div class="card-body p-3">
              <div class="row pb-4">
                <div class="col text-center">
                  <div>
                    <span class="sub-text">Purchase history</span>
                  </div>
                  <div class="mt-2 mb-2">
                    <span class="big-green">{{numeral(statistics.buyerHistory[purchasesHistoryRange]/100).format("$0,0.00")}}</span>
                  </div>
                  <div class="sub-text">
                    <a
                      class="m-1"
                      @click="purchasesHistoryRange = '1d'"
                      :class="{'active': purchasesHistoryRange == '1d' }"
                      href="#"
                    >1D</a>
                    <a
                      class="m-1"
                      @click="purchasesHistoryRange = '1w'"
                      :class="{'active': purchasesHistoryRange == '1w' }"
                      href="#"
                    >1W</a>
                    <a
                      class="m-1"
                      @click="purchasesHistoryRange = '1m'"
                      :class="{'active': purchasesHistoryRange == '1m' }"
                      href="#"
                    >1M</a>
                    <a
                      class="m-1"
                      @click="purchasesHistoryRange = 'all'"
                      :class="{'active': purchasesHistoryRange == 'all' }"
                      href="#"
                    >ALL</a>
                  </div>
                </div>
                <div class="col text-center">
                  <div>
                    <span class="sub-text">Watched Listings</span>
                  </div>
                  <div class="mt-2 mb-2">
                    <span class="big-green">{{watchedListings}}</span>
                  </div>
                  <div class="sub-text">
                    <a class="m-1" href="#0" @click="goToWatchedItems">View All</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header p-3" style="border-bottom: 0px; background-color: #EAEAEA">
              <span class="card-title h6 mt-2">Seller history</span>
            </div>

            <div class="card-body p-3">
              <div class="row pb-4">
                <div class="col text-center">
                  <div>
                    <span class="sub-text">Sales History</span>
                  </div>
                  <div class="mt-1">
                    <span class="sub-big-green">Total: {{numeral((statistics.sellerHistory[salesHistoryRange].net  + statistics.sellerHistory[salesHistoryRange].tax) /100).format("$0,0.00")}}</span>
                  </div>
                  <div class="mb-3">
                    <span class="sub-big-green">Net: {{numeral((statistics.sellerHistory[salesHistoryRange].net)/100).format("$0,0.00")}}</span>
                  </div>

                  <div class="sub-text">
                    <a
                      class="m-1"
                      @click="salesHistoryRange = '1d'"
                      :class="{'active': salesHistoryRange == '1d' }"
                      href="#"
                    >1D</a>
                    <a
                      class="m-1"
                      @click="salesHistoryRange = '1w'"
                      :class="{'active': salesHistoryRange == '1w' }"
                      href="#"
                    >1W</a>
                    <a
                      class="m-1"
                      @click="salesHistoryRange = '1m'"
                      :class="{'active': salesHistoryRange == '1m' }"
                      href="#"
                    >1M</a>
                    <a
                      class="m-1"
                      @click="salesHistoryRange = 'all'"
                      :class="{'active': salesHistoryRange == 'all' }"
                      href="#"
                    >ALL</a>
                  </div>
                </div>
                <div class="col text-center">
                  <div>
                    <span class="sub-text">Current Listings</span>
                  </div>
                  <div class="mt-1">
                    <span class="sub-big-green">Total: {{numeral(currentListings.total).format("$0,0.00")}}</span>
                  </div>
                  <div class="mb-3">
                    <span class="sub-big-green">Net: {{numeral(currentListings.net).format("$0,0.00")}}</span>
                  </div>
                  <div class="sub-text">
                    <a class="m-1" href="#0" @click="goToMyListings">View All</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="card-footer p-4"> </div> -->
          </div>

          <div class="card mt-3">
            <div class="card-header p-3" style="border-bottom: 0px; background-color: #EAEAEA">
              <span class="card-title h6 mt-2">Login History</span>
            </div>

            <div class="card-body p-3" style="overflow-y: auto;">
              <table class="table table-condensed table-sm table-striped table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Action</th>
                    <th scope="col">Location</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in loginInfo" v-bind:key="key">
                    <td>{{value.action}}</td>
                    <td>{{value.location}}</td>
                    <td>{{value.date}}</td>
                    <td>{{value.time}}</td>
                  </tr>
                </tbody>
              </table>

              <div class="text-center">
                <router-link
                  :to="{ path: 'activity', query: { filter: 'USER_LOGIN' } }"
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a class :href="href" @click="navigate">View all information and logins</a>
                </router-link>
                <!-- <a href="#0">View all information and logins</a> -->
              </div>
            </div>

            <!-- <div class="card-footer p-4"> </div> -->
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Moment from "moment";
var momentTimezone = require('moment-timezone');
var qs = require('qs');

import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
const speakeasy = require("speakeasy");
const QRCode = require("qrcode");
const api = require('../api');
const numeral = require('numeral');

let passwordValidator = require("password-validator");
let schema = new passwordValidator()
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

export default {
  data() {
    return {
      tradeLink: "",
      storeUrl: "",
      emailAddress: "",
      password: "*********",
      purchasesHistoryRange: 'all',
      salesHistoryRange: 'all',
      statistics: {
        buyerHistory: { '1d': 0, '1w': 0, '1m': 0, 'all': 0 },
        sellerHistory: { '1d': { gross: 0, tax: 0 }, '1w': { gross: 0, tax: 0 }, '1m': { gross: 0, tax: 0 }, 'all': { gross: 0, tax: 0 } },
      },
      watchedListings: 0,
      loginActivities: [],
      currentListings: {
        total: 0,
        net: 0
      },
      invalidPassReqs: [],
      lastAction: null
    };
  },
  computed: {
    loginInfo() {
      let locale = moment.tz.guess();
      return this.loginActivities.filter(x => x.additionalInformation).map(x=> ({
        action: x.additionalInformation.steam === true ? "Steam Login" : "Login",
        location: x.additionalInformation.ipInfo ? (x.additionalInformation.ipInfo.region_name + ", " + x.additionalInformation.ipInfo.city + ", " + x.additionalInformation.ipInfo.region_code + " (" + x.additionalInformation.ipInfo.country_code + ")") : "Unknown",
        date: moment(x.createdAt).format("MMM DD, YYYY"),
        time: momentTimezone(x.createdAt).tz(locale).format("h:mm A zz"),
      })).splice(0, 4);
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    numeral,
    connectSteam() {
      this.$store.dispatch('auth/connectSteam', { rememberMe: true });
    },
    openTradeoffersInNewTab(){
      window.open(this.user.steamData.profileurl + '/tradeoffers/privacy#trade_offer_access_url', '_blank');
    },
    openSteamInNewTab() {
      window.open(this.user.steamData.profileurl, '_blank');
    },
    async removeTwoFactor() {
      let result = await Swal.fire({
        title: "Disable Account Security",
        icon: "warning",
        input: "text",
        html: "Please enter a current two factor code, or revocation code" 
      });

      if (result.dismiss) return;

      let code = result.value;
      if (!code) return;

      Swal.showLoading();

      try {
        let updateRequest = await this.$store.dispatch("auth/updateUser", {
          changes: [{
            key: "twoFactorSecret",
            value: null,
          }],
          context: {
            revocationCode: code
          }
        });

        Swal.fire("Two Factor Disabled", `Please consider re-activating in the future.`, "success");
      } catch (e) {
        console.log(e.stack);
        Swal.fire("Error", "Unable to remove account security", "error");
      }

    },
    copyToClipboard(text){
      let self = this;
      this.$copyText(text).then(function (e) {
        //console.log(e)
        self.$toasted.show('Copied!',{
          duration: 1000,
          position: 'bottom-left'
        });
      }, function (e) {
        console.error('Failed to copy to clipboard.');
      })
    },
    validateTradeOfferInput(text){
      // https://steamcommunity.com/tradeoffer/new/?partner=160911784&token=vb9NrdIi
      let regex = /https\:\/\/steamcommunity\.com\/tradeoffer\/new\/?\?partner=([0-9]+)&token=(([a-zA-Z0-9]|\-|\_)+)/
      console.log(regex.test(text))
      return regex.test(text)
    },
    goToWatchedItems(){
      this.$router.push("/watchlist");
    },
    goToMyListings(){
      this.$router.push("/my-listings");
    },
    async updateTradeLink() {
      if(this.validateTradeOfferInput(this.tradeLink)){
        try {
          let user = this.$store.getters["auth/user"];
          let response = await this.$store.dispatch("auth/updateUser", [
            {
              key: "tradeOfferUrl",
              value: this.tradeLink,
              oldValue: user.tradeOfferUrl
            }
          ]);
          console.log(response);
          let error = response.data.errors.find(x => x.key == "tradeOfferUrl")
          if (error) {
            return Swal.fire('Unable to save', error.message, 'error');
          }
          Swal.fire('Trade offer saved!', "", 'success');
        } catch (e) {
          console.log(e.message);
          let error = "An unknown error occured while trying to save your TradeOffer URL";
          if (e.response && e.response.data && e.response.errors) error = e.response.data.errors[0] 
          Swal.fire('Invalid trade offer entered', error, 'error')
        }
      }
      else{
        Swal.fire('Invalid trade offer entered', 'Trade offer URL must be in the correct format.', 'error')
      }
    },
    async changeEmail() {
      try {
        let token;

        let email = this.user.email;
        // If they do not have an email, let them set it.
        // If they have confirmed it, let them change it 
        if (!this.user.email || this.user.emailConfirmedAt) {
          let emailReponse = await Swal.fire({
            title: "Please enter email",
            input: "email",
            inputPlaceholder: "john@doe.com",
          });
          
          if (!emailReponse.value) return;
          email = emailReponse.value;
        }

        if (this.user.twoFactorEnabled) { 
          let response = await Swal.fire({
            title: "Confirm Two Factor Code",
            html: "Please enter your two factor code displayed on your authenticator",
            input: "text",
            icon: "info",
            inputValidator: (value) => {
              if (!value) return 'You need to write something!'
              if ((value + '').length != 6) return 'Your code must be 6 digits.';
              if (isNaN(parseInt(value))) return 'Your code must be 6 digits.';
            }
          });
          token = response.value;
        }

        // Otherwise and also after they complete it, send email
        await api.post(`/change-email?${qs.stringify({ verify: this.user.emailConfirmedAt ? null : '1', email })}`, {}, { headers: { 'guard-twofactor-token': token } });
        Swal.fire({icon: 'info', text: 'An email was sent to your address. Check your inbox for the email change/verify link.'})
        

        // Updating store for request spam prevention (also updated on api call)
        this.$store.dispatch("auth/updatelastRequestedEmailChange")
    } catch (e) {
        Swal.fire("Error", "An unknown error occured, please try again later", 'error');
        console.error(e)
        throw e; // Throw for sentry to catch
      }
    },
    async changePassword() {
      // Preventing more than 1 request every 2 mins
      let token;
      if (this.user.twoFactorEnabled) { 
        let response = await Swal.fire({
          title: "Confirm Two Factor Code",
          html: "Please enter your two factor code displayed on your authenticator",
          input: "text",
          icon: "info",
          inputValidator: (value) => {
            if (!value) return 'You need to write something!'
            if ((value + '').length != 6) return 'Your code must be 6 digits.';
            if (isNaN(parseInt(value))) return 'Your code must be 6 digits.';
          }
        });
        token = response.value;
      }
      
      await api.post('/change-password', {}, { headers:{ 'guard-twofactor-token': token } });
      Swal.fire({icon: 'info', text: 'An email was sent to your address. Check your inbox for the password change link.'});
    },
    async setupPassword() {
      this.$router.push("/password-setup")
    },
    async setupTwoFactor() {
      let user = this.$store.getters["auth/user"];
      if(!user.email){ // Create SWAL and tell user to update email
        return Swal.fire("Error", "No email address set. You cannot set up Two-Factor Authentication without a valid email address.", "error")
      }

      // Pass in label for email/other user details and issuer for app name (guardgg)
      // TODO: Get secret from API?
      let secret = speakeasy.generateSecret({
        length: 16,
        symbols: false,
        otpauth_url: true,
        name: "Guard.GG " + user.email,
      });

      let imageUrl = await new Promise(function (resolve, reject) {
        QRCode.toDataURL(secret.otpauth_url, async function(err, image_data) {
          if (err) return reject(err);
          resolve(image_data);
        });
      });

      let result = await Swal.fire({
        imageUrl,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Custom image",
        html: `Scan this QR code on your phone to set up your authenticator. We recommend using the Google Authenticator or Microsoft Authenticator app. <br><br> Alternatively you can type in this code: <br><div class="d-flex justify-content-center mt-2"><div class="copyme">${secret.base32}</div></div>`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: `Next`,
        cancelButtonText: `Cancel`,
        heightAuto: false,
      })

      if (result.dismiss) return console.log("2FA cancelled");

      result = await Swal.fire({
        title: "Confirm Two Factor Code",
        html: "Please enter your two factor code displayed on your authenticator",
        input: "text",
        icon: "info",
        inputValidator: (value) => {
          if (!value) return 'You need to write something!'
          if ((value + '').length != 6) return 'Your code must be 6 digits.';
          if (isNaN(parseInt(value))) return 'Your code must be 6 digits.';
        }
      });

      if (result.dismiss) return; // cancelled

      // Allow for 30 seconds of drift
      let isVerified = 
        speakeasy.totp.verify({ secret: secret.ascii, encoding: 'ascii', token: result.value, time: Math.floor(Date.now()/1000) - 30 }) || 
        speakeasy.totp.verify({ secret: secret.ascii, encoding: 'ascii', token: result.value }) ||
        speakeasy.totp.verify({ secret: secret.ascii, encoding: 'ascii', token: result.value, time: Math.floor(Date.now()/1000) + 30 });

      if (!isVerified) {
        return Swal.fire("Invalid code", "The two factor code entered is invalid.", "error");
      }

      // TODO: Confirm code is valid
      try {
        let update = await this.$store.dispatch("auth/updateUser", [
          {
            key: "twoFactorSecret",
            value: secret.ascii,
            oldValue: user.twoFactorSecret,
          }
        ]);
        console.log(update);
        Swal.fire("Account Secured", `Please record this revocation code: <br><div class="d-flex justify-content-center mt-2"><div class="copyme">${update.data.resource.twoFactorRevocationCode}</div></div><br> You will require this to disable two factor in the future.`, "success");
      } catch (e) {
        console.log("update failed?");
        console.log(e.stack);
        
      }
    },
    maskEmail(email) {
      let parts = email.split('@');
      let final = parts[0].substring(0, 1) + "***@" + parts[1].substring(0, 1) + "***.***";
      return final;
    },
  },
  watch: {},
  async mounted() {
    let user = this.$store.getters["auth/user"];
    this.tradeLink = user.tradeOfferUrl;
    console.log(`Got email: ${user.email}`)
    try { this.emailAddress = this.maskEmail(user.email || "") } catch (e) {};
    // Getting past user transactions
    
    try {
      let { data: { statistics: stats }} = await api.axios.get(`/users/me/statistics`);
      this.statistics = stats;
    } catch (e) {
      console.error(`Unable to load stats`)
    }
    // Aggregating listings stats
    await this.$store.dispatch("items/updateMyListings");
    let listings = this.$store.getters["items/my_listings"];
    let sum = 0;
    for(let listing of listings){
      sum += listing.listPrice;
    }
    this.currentListings.total = sum;
    this.currentListings.net = sum*0.95; // Assuming 5% tax on each item for now
    // Watchlist total
    this.$store.dispatch("watchlist/updateWatchlist")
    this.watchedListings = this.$store.getters["watchlist/count"] || 0;

    console.log(`Got to confirm email code `)
    if (this.$route.query.confirmEmailCode) {
      try {
        Swal.fire({ title: `Confirming email`, showLoading: true });
        await api.get(`/activate-email?${qs.stringify({ code: this.$route.query.confirmEmailCode })}`);
        Swal.fire({ title: `Email confirmed`, icon: 'success' });
      } catch (e) {
        console.log(`Error confirming error`);
        console.log(e);
        Swal.fire({ title: "Error confirming email", icon: 'danger', text: "Please try again later" })
      }
    }

    try {
      this.loginActivities = (await api.getActivities({ type: "USER_LOGIN" })).data;
    } catch (e) {
      console.log("Could not load login activity")
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  font-size: 14px;
  font-family: "Roboto";
}
.form-text {
  font-family: "Roboto";
  color: #545454;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
}

.active {
  text-decoration-line: underline;
  color: #ed6b33 !important; // Overriding guard.scss
}

.sub-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 3px;
  /* or 50% */

  text-align: center;

  color: #545454;
}

.big-green {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 62px;
  /* or 316% */

  text-align: center;

  color: #7baa64;
}
.sub-big-green {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  /* or 316% */

  text-align: center;

  color: #7baa64;
}

.card {
  border-radius: 10px;
}

.my-textbox {
  border: 1px solid gray;
  width: 300px;
  color: black !important;
  background: white !important;
  padding-left:10px !important;

}
</style>